<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>内容管理</el-breadcrumb-item>
                <el-breadcrumb-item>一日游</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/tourGroup/list' }">旅行社</el-breadcrumb-item>
                <el-breadcrumb-item>查看</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">
            <el-form ref="form" :model="form" label-width="150px" class="el-form-self">

                <el-row>
                    <el-col>
                        <el-form-item label="名称:" prop="name">
                            <div class="view-text">{{form.name}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="简介:" prop="introduction">
                            <div class="view-text">{{form.introduction}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="联系电话:" prop="contactNumber">
                            <div class="view-text">{{form.contactNumber}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="地址:" prop="address">
                            <div class="view-text">{{form.address}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label="星级评分:" prop="rate">
                            <el-rate v-model="form.rate" disabled show-score text-color="#ff9900" score-template="{value}颗星">
                            </el-rate>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="点赞人数:" prop="likeCount">
                            <div class="view-text">{{form.likeCount}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="列表缩略图片:" prop="indexImageUrl" :disabled=true>
                            <img v-if="indexImageUrl" :src="indexImageUrl" class="homeBannerImage">
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="详情页图片:" prop="detailImageUrl" :disabled=true>
                            <img v-if="detailImageUrl" :src="detailImageUrl" class="homeBannerImage">
                        </el-form-item>
                    </el-col>
                </el-row>

                   <el-row>
                    <el-col>
                        <el-form-item label="旅行社Logo:" prop="log" :disabled=true>
                            <img v-if="log" :src="log" class="homeBannerImage">
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="置顶:" prop="isTop">
                            <div class="view-text">{{form.isTop==0?'是':form.isTop==1?'否':''}}</div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="form-buttons">
                    <el-col>
                        <el-form-item class="el-form-item-self">
                            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>


        </el-col>
    </el-row>

</template>

<script>
    import {reqGetTourGroupOne} from '../../../api/tour-api'
    import {uploadFileURL,downloadFileURL} from '../../../api/system-api'
    export default {
        data(){
            return{
                uploadFileURL:uploadFileURL,
                downloadFileURL:uploadFileURL,
                indexImageUrl: '',
                detailImageUrl: '',
                log:'',
                form: {
                    name: '',
                    contactNumber:'',
                    indexImageUrl: '',
                    detailImageUrl: '',
                    isTop:'',
                    introduction:'',
                    rate:0,
                    address:'',
                    log:''
                },
            }
        },
        methods:{

            //列表缩略图片
            beforeIndexImageUpload(file) {
                const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
                const isLt1M = file.size / 1024 / 1024 < 1;

                if (!isValidType) {
                    this.$message.error('不支持改类型的文件上传。');
                }
                if (!isLt1M) {
                    this.$message.error('上传文件大小不能超过 1MB!');
                }
                return isValidType && isLt1M;
            },
            handleIndexImageSuccess(response) {
                if (response.code === 1) {
                    this.form.indexImageUrl = response.data;
                    this.indexImageUrl=downloadFileURL + response.data + "/";
                    console.log(this.indexImageUrl)
                }
            },

            //详情页图片
            beforeDetailImageUpload(file) {
                const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
                const isLt1M = file.size / 1024 / 1024 < 1;

                if (!isValidType) {
                    this.$message.error('不支持改类型的文件上传。');
                }
                if (!isLt1M) {
                    this.$message.error('上传文件大小不能超过 1MB!');
                }
                return isValidType && isLt1M;
            },
            handleDetailImageSuccess(response) {
                if (response.code === 1) {
                    this.form.detailImageUrl = response.data;
                    this.detailImageUrl=downloadFileURL + response.data + "/";
                }
            },
            getTourGroupOne(id){
                var that=this;
                reqGetTourGroupOne({id:id}).then(res=>{
                    if(res.data.code===1){
                        if(res.data.data.rate){
                            res.data.data.rate=Number(res.data.data.rate)
                        }
                        that.form=res.data.data;
                        if(that.form.indexImageUrl){
                            that.indexImageUrl=downloadFileURL+that.form.indexImageUrl+'/'
                        }
                        if(that.form.detailImageUrl){
                            that.detailImageUrl=downloadFileURL+that.form.detailImageUrl+'/'
                        }
                         if(that.form.log){
                            that.log=downloadFileURL+that.form.log+'/'
                        }
                        if(!that.form.rate){
                            that.form.rate=0
                        }
                    }else{
                        that.$message({
                            message: '查询失败',
                            type: 'error'
                        });
                    }
                }).catch(()=>{
                    that.$message({
                        message: '查询失败',
                        type: 'error'
                    });
                })
            }
        },
        mounted(){
            let id = this.$route.query.id;
            this.getTourGroupOne(id);
        }
    }
</script>

<style scoped>

</style>
